table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
  text-align: left;
  text-justify: auto;
}

tr:nth-child(odd) {
  color: var(--admincat-color-grey-12);
  text-align: left;
  text-justify: auto;
}

.infoEmptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12rem;
}

.infoEmptyStateText {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 2rem;
  padding-top: 0.5rem;
  color: var(--admincat-color-grey-12);
}

.lottie_loader {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading_text {
  font-style: normal;
  font-weight: 600;
  font-size: 11;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #9fa7b0;
  margin-bottom: 8px;
}
