:root {
  --admincat-color: #739bcb;
  --admincat-dark-color: #2d5a90;
  --admincat-medium-color: #5e7698;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: helvetica, sans-serif;
  /* background-color: var(--admincat-color-grey-4); */
}

div.main {
  position: relative;
  width: 100vw;
  height: 100%;
  min-height: 90vh;
  background: #fff; 
  /* background: #f5f7fb;  */
  /* background: var(--admincat-color-grey-4); */
}


div.main.authenticated {
  background: var(--admincat-color-grey-0);
  margin-bottom: -20px;
}

div#logo a {
  display: inline-block;
  width: 167px;
  height: 48px;
}

div#logo a img {
  width: 100%;
  height: 100%;
}

section.banner-area {
  height: 45vh;
  min-height: 400px;
}

section.banner-area div.banner-content {
  margin-top: 0px;
}

button.unset-properties {
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: inherit;
}

div.datepicker-style input {
  background-color: transparent;
  border-bottom: 1px solid #ccc;
  border: 1px solid #ccc;
  border-radius: 0;
  outline: 0;
  height: 2.1rem;
  width: 100%;
  font-size: 1rem;
  box-shadow: none;
  box-sizing: content-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding-left: 10px;
}
div.react-datepicker-popper {
  z-index: 10;
}

small#passwordHelp {
  display: none;
}
