.container {
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: var(--admincat-color-grey-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  margin-bottom: 14px;
}

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 11;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: var(--admincat-color-grey-15);
  margin-bottom: 8px;
}

.button {
  width: 129px;
  height: 39px;
  background: #4c81be;
  border: 1px solid #1d579b;
  box-sizing: border-box;
  border-radius: 7px;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
}
