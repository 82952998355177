/* .container {
  display: flex;
  flex-flow: column;
} */

.settingsContainer {
  display: flex;
  margin-bottom: -60px;
  margin-right: -35px;
  justify-content: right;
  margin-top: 15px;
}
/* .settingsContainer {
  display: flex;
  margin-bottom: -50px;
  margin-right: -40px;
  justify-content: right;
} */

.settingBtn {
  background: #000;
}

.settingOptionsContainer {
  background-color: #333;
  width: 16rem;
  max-height: 300px;
  position: absolute;
  align-items: center;
  z-index: 1;
  margin-top: 40px;
  margin-right: -20px;
  border-radius: 5px;
  padding: 20px;
  overflow-y: auto;
}

/* Customize the label (the container) */
.dropdown {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  color: azure;
  font-size: 14px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.dropdown input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.dropdown:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.dropdown input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.dropdown input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.dropdown .checkmark:after {
  left: 6px;
  top: 2px;
  width: 8.5px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.itemWrapper {
  background: #ffffff;
  /* border: medium solid aqua; */
  border-radius: 20px;
  /* transform: translate(389px, 30px); */
}

.itemContent {
  padding: 8px;
}

/* cards */
.grid_cardTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* color: #000000; */
  color: var(--admincat-color-grey-2);
  text-align: left;
}

/* Cards */
.grid_cardContainer {
  border-radius: 6px;
  /* background-color: #ffffff; */
  background: var(--admincat-color-grey-1);
  box-shadow: 0px 2px 4px rgb(126 142 177 / 12%);
  border-width: 1px;
  border-color: #e1e3e8;
  cursor: pointer;
}

.grid_cardtop {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--admincat-color-grey-5);
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  width: 100% !important;
  margin-bottom: 5px;
}

.grid_cardtop h5 {
  margin: 0px;
  padding: 0px;
}

.grid_cardBottomContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100% !important;
  width: 100% !important;
}

.cardbottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.grid_cardbottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.grid_cardBottomChart {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100% !important;
  height: 70% !important;
}

.grid_graph_cardBottomChart {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100% !important;
  height: 100% !important;
}

.grid_graph_top5Contract {
  justify-content: center;
  align-self: center;
  width: 100% !important;
  height: 15rem;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.grid_graph_top5Contract::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.grid_graph_top5Contract {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.grid_cardCounter {
  display: flex;
  flex-direction: column;
}

.grid_emailUnread {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /* color: #a7acad; */
  color: var(--admincat-color-grey-22);
}

.grid_emailRead {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #317ed9;
}

.grid_counter {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #317ed9;
}

.grid_counter-long {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #317ed9;
}

.grid_unit {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* color: #a7acad; */
  color: var(--admincat-color-grey-22);
  text-align: right;
}

.grid_storage {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  /* color: #424546; */
  color: var(--admincat-color-grey-15);
  text-align: right;
}

.grid_stroge small {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #a7acad;
  text-align: right;
}

.grid_cardBottomStorage {
  width: 100% !important;
  background-color: #317ed9;
}

.grid_progressBar {
  width: 100% !important;
}

.grid_space_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: left;
  padding: 10px;
  height: 15rem;
  width: 100%;
}

.grid_space_group_image {
  border-radius: 50%;
  border-width: 4px;
  margin: 7px;
}

.grid_notification {
  height: 25rem;
  overflow: auto;
  width: 100%;
}

.grid_notification ul {
  margin: 0px;
  padding: 0px;
}

.grid_notification ul li {
  list-style: none;
  display: block;
  padding: 15px;
  margin: 10px;
  font-size: 12px;
  border-radius: 5px;
  position: relative;
}

.grid_notification ul li:nth-child(even) {
  background: #dceef6;
}

.grid_notification ul li:nth-child(odd) {
  background: #ccc;
}
.grid_notification ul li span {
  position: absolute;
  display: block;
  width: 20px;
  right: 0px;
  top: 0px;
}

.grid_graph {
  /* display: flex; */
  /* padding: 10px; */
  /* align-items: center;
  justify-content: center; */
  width: 100%;
  height: 90%;
  /* padding: 2rem 0; */
}

.grid_graph_donut {
  width: 100%;
  height: 90%;
  /* overflow: auto; */
}

.infoEmptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.infoEmptyStateText {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 2rem;
  padding-top: 0.5rem;
  color: var(--admincat-color-grey-15);
}
