
div.form-check input[type=checkbox]{
  display: none;
}


div.form-check input[type=checkbox] + label.check-box{
  display: block;
  cursor: pointer;
}

div.form-check input[type=checkbox] + label.check-box:before{
  content: "\2713";
  border: 0.1em solid #2196F3;
  width: 26px;
  height: 27px;
  padding-left: 2px;
  display: inline-block;
  border-radius: 3px;
  font-size: 20px;
  color: transparent;
  transition: 0.2s;
}

div.form-check input[type=checkbox]:checked + label.check-box:before{
  background-color: #2196F3;
  color: #fff;
  border-color: #2196F3;
}

div.form-check input[type=checkbox] + label.check-box:active:before{
  transform: scale(2);
}

div.form-check label.form-check-label {
  margin-left: 40px;
}
.md-form label,
.md-form label.active {
  font-size: 13px !important;
}
input.form-control{
  width: 75% !important;
  font-size: 14px;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill{
  background: #fff !important;
}

.m-t-60{
  min-height: 400px;
}

.m-t-60.login {
  /* min-height: 500px; */
}

div.switches{
  margin-bottom: 8px;
}

div.switches label.custom-switch{
  margin: 3px 0px;
  top: 0;
  position: relative;
  cursor: pointer;
}

.custom-switch-input{
  cursor: pointer;
}

.custom-switch-input:checked ~ .custom-switch-indicator{
  background: #2196F3;
}

.md-form label.active {
    -webkit-transform: translateY(-90%);
    -ms-transform: translateY(-90%);
    transform: translateY(-90%);
    font-size: .8rem;
}

.md-form select.select {
  position: relative;
  left: 47px;
  top: 5px;
  width: 84%;
}

.md-form input.input-file {
  width: 83% !important;
}

.input-file-wrapper{
  position: relative;
}

.input-file-wrapper .file-label {
  margin: 0 !important;
  position: absolute;
  left: 51px;
  top: 4px;
  border: 1px solid;
  width: 81%;
  padding: 4px;
  padding-left: 10px;
  z-index: 2;
  color: #fff;
  letter-spacing: 0.7px;
  background: #2196F3;
  border-radius: 3px;
  font-size: 14px !important;
  /* font-weight: 600; */
  cursor: pointer;
  /* temp */
  display: none;
}

.md-form div.tiny-info {
  font-size: 12px;
  /* font-weight: 600; */
  letter-spacing: 0.4px;
  display: inline-block;
  position: absolute;
  top: 38px;
  left: 44px;
}

div.cert-label {
  margin-left: 47px;
  font-size: 14px;
  font-weight: 600;
  color: #2196F3;
}

span.custom-control-label::after {
  position: absolute;
  top: 2px;
  left: -1px;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  background-color: #2196F3;
  border-radius: 50%;
  padding: 10px;
}

div.org-name-wrapper{
  margin-bottom: 10px;
}

div.org-name-wrapper i.org-name-icon {
  top: -5px;
}

div.org-name-wrapper input.org-name {
  border: 1px solid #ddd;
  width: 76% !important;
  height: 24px;
  border-radius: 3px;
}

div.custom-controls-stacked{
  display: flex;
  font-size: 12px;
}


/* styles for the password strength information */
div.password-block {
  position: relative;
}

input.password:focus ~ div.block-info {
  display: block;
}

div.password-block div.block-info {
  display: none;
  position: absolute;
  bottom: 54px;
  left: 46px;
  width: 80%;
  min-width: 200px;
  background: #f5f7fb;
  border: 1px solid;
  z-index: 10;
  font-size: 12px;
  font-weight: 600;
  padding: 7px 13px;
  border-radius: 4px;
  border-color: #ccc;
}

div.password-block div.block-info::before {
  content: "";
  width: 0px;
  height: 0px;
  border-top: 15px solid #dddddd;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  bottom: -15px;
  right: 30px;
}

div.block-info span.block-info-item {
  display: block;
  line-height: 17px;
}
/* end */




/* another */
._flex-column{
  display: flex;
  flex-flow: column;
}


div.form-wrapper .forms-title{
  font-weight: 600;
  color: #356DC7;
}

div.form-group .form-label,
div.form-wrapper .form-label {
  color: #5E7698;
}

div.form-wrapper span.required {
  color: red;
}

div.form-group .forms-input,
div.form-wrapper .forms-input {
  border: 1px solid #E0E5EC;
  width: 98% !important;
  border-radius: 3px;
  padding: 1.5px;
  padding-left: 10px;
  box-sizing: border-box;
}

div.form-group .settings-input {
  width: 96% !important;
}

div.form-wrapper .forms-file {
  width: 100% !important;
  padding: 8px;
}

.forms-select {
  height: 45px !important;
}


div.form-wrapper input:focus,
div.form-group input:focus{
  box-shadow: none !important;
  border: 1px solid #a3b2c7 !important;
}


div.form-group input:disabled,
div.form-wrapper input:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

div.form-group .btn-display {
  background: none;
  border: 1px solid #E0E5EC;
  color: #777 !important;
  box-shadow: none;
  position: relative;
  top: -3px;
  text-transform: capitalize;
}

div.form-group a.change-pw {
  font-size: 14px;
  position: relative;
  top: -12px;
  display: block;
}

div.form-group p.admin {
  color: orange;
  font-size: 13px;
  letter-spacing: 0.5px;
}

div.page-subtitle i.fe {
  font-weight: 600;
  font-size: 14px;
  position: relative;
  top: -1px;
}

.btn-primary{
  text-transform: capitalize;
}

._flag-select {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
}

div.flag-select div.flag-options {
  font-size: 14px;
  position: absolute;
  z-index: 10;
  background: #fff;
  border: 1px solid #ccc;
  padding: 7px;
  border-radius: 3px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

div.d-flex {
  /* flex-wrap: wrap; */
  justify-content: space-between;
}

div.d-flex a.btn-link {
  color: #295a9f !important;
  text-transform: capitalize;
}

div.d-flex button.btn-primary {
  text-transform: capitalize;
}

div.selected--flag--option {
  outline: none;
}

span.country-flag img {
  width: 50px;
  margin-right: 10px;
}

div.card-payment {
  font-size: 13px;
}

div.card-payment label.counter {
  right: 19px;
  top: -1px;
}

div.card-payment ._payment-checkbox {
  position: relative;
  top: 14px;
  left: 10px;
  display: inline-block;
  z-index: 10;
}

div.card-payment .colorinput-color{
  width: 15px;
  height: 15px;
}

div.card-payment .colorinput-color::before {
  top: 1px;
  left: -2px;
  height: 18px;
  width: 18px;
}

.custom-controls-stacked.custom-style{
  font-size: 13px;
  letter-spacing: 0.5px;
}

.custom-style label.custom-control-inline {
  margin-right: 50px;
}
