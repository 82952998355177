.interval_btn_container {
  display: flex;
  flex-direction: row;
  gap: 0.57rem;
  height: 2.5rem;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 1rem;
}

.btn {
  background-color: #d5e4f7;
  color: #4576b3;
}

.active_btn {
  background-color: #4c81be;
  color: #ffffff;
}
