


/* @font-face {
    font-family: 'Rajdhani';
    src: local('Rajdhani'), url(./fonts/Rajdhani/Rajdhani-Regular.ttf) format('truetype');
  } */
  /* @font-face {
    font-family: 'LetterOMatic';
    src: local('LetterOMatic'), url(./fonts/LetterOMatic.ttf) format('truetype');
  } */