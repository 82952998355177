*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.lottie_loader {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12rem;
}

.dash {
  padding-top: 20px;
  margin-left: 1.5rem;
}

/* .cardCounter {
} */

/* .dashCardContainer {
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgb(126 142 177 / 12%);
  margin-bottom: 15px;
  width: 100% !important;
  border-width: 1px;
  border-color: #e1e3e8;
}

.cardtop2 {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  padding: 10px;
  padding-right: 20px;
} */

/* .space_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: left;
  padding: 10px;
  height: 15.8rem;
}

.space_group_image {
  border-radius: 50%;
  border-width: 4px;
  border-color: red;
  margin: 10px;
}

.groups {
  margin: 10px;
}

.cardGraphContainer {
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgb(126 142 177 / 12%);
  margin-bottom: 15px;
  width: 100% !important;
  border-width: 1px;
  border-color: #e1e3e8;
} */

/* .graphOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.graphOptions label {
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
} */

/* .top-act {
  margin: 0px;
  padding: 0px;
  display: block;
}

.top-act li {
  list-style: none;
  display: block;
  width: auto;
  float: right;
}

.top-act li a {
  display: block;
  width: 30px;
  height: 30px;
  padding: 3px;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  border-radius: 50%;
  background: #ccc;
  margin-top: 15px;
}

.top-act li:first-child a {
  margin-right: 0px;
}

.top-act li a:hover {
  opacity: 0.8;
}

.crdbm {
  height: 25rem;
  overflow: auto;
}

.crdbm ul {
  margin: 0px;
  padding: 0px;
}

.crdbm ul li {
  list-style: none;
  display: block;
  padding: 15px;
  margin: 10px;
  font-size: 12px;
  border-radius: 5px;
  position: relative;
}

.crdbm ul li:nth-child(even) {
  background: #dceef6;
}

.crdbm ul li:nth-child(odd) {
  background: #ccc;
} */

/* .crdbm ul li span {
  position: absolute;
  display: block;
  width: 20px;
  right: 0px;
  top: 0px;
}

.spc {
  padding: 10px;
  height: 208px;
  overflow: auto;
}

.spc ul {
  margin: 0px;
  padding: 0px;
}

.spc ul li {
  list-style: none;
  display: block;
  width: 93px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.spc ul li img {
  width: 93px;
  height: 93px;
  border-radius: 50%;
}

.grph {
  display: flex;
  padding: 10px;
  height: 25rem;
  align-self: center;
}

.tpf {
  margin-top: 10px;
}

.slc {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
}

.nbrd {
  border-bottom: none;
} */

/* End od custom style */
/* .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.p-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
} */

/* margins End */
/* .cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8,
.cls-9,
.cls-10,
.cls-11,
.cls-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.cls-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.cls-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.cls-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.cls-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.cls-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.cls-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.cls-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.cls-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.cls-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.cls-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.cls-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.cls-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (max-width: 800px) {
  .dashCardContainer,
  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9,
  .cls-10,
  .cls-11,
  .cls-12 {
    position: relative;
    max-width: 100% !important;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    display: block;
    flex: none;
  }
} */

/* @media (max-width: 1200px) {
  .dashCardContainer,
  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9,
  .cls-10,
  .cls-11,
  .cls-12 {
    position: relative;
    max-width: 100% !important;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    display: block;
    flex: none;
  }
} */
