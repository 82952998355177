.filterAreaBox {
  flex: 1;
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--admincat-color-grey-5);
  align-items: center;
  padding-left: 1rem;
  color: var(--admincat-color-grey-12);
}

.seachButton_documentPage {
  /* width: 8.0625rem; */
  width: 20%;
  height: 2.125rem;
  background: #4c81be;
  border: 1px solid #1d579b;
  box-sizing: border-box;
  border-radius: 0px 7px 7px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #ffffff;
  padding-right: 1.1875rem;
}

.filterAreaContentText {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 3rem;
  /* color: #2c2d2f; */
  color: var(--admincat-color-grey-12);
  letter-spacing: -0.017rem;
  padding-left: 0.9375rem;
}

/* Style the sidenav links and the dropdown button */
.dropdown-btn {
  display: flex;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 1re;
  line-height: 3rem;
  /* color: #2c2d2f; */
  color: var(--admincat-color-grey-12);
  letter-spacing: -0.017rem;
  padding-left: 0.5375rem;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 1re;
  line-height: 3rem;
  /* color: #2c2d2f; */
  color: var(--admincat-color-grey-12);
  letter-spacing: -0.017rem;
  padding-left: 0.5375rem;
}

.chose_date {
  width: 7rem;
  margin-right: 1rem;
}

.clear-btn {
  cursor: pointer;
  background: none;
  border: none;
  margin-left: 2rem;
  align-self: center;
}

.input_date {
  width: 6rem;
  margin-right: 1rem;
}

.dropdown-title-container {
  padding-left: 3.1875rem;
  height: 6rem;
}

.dropdown-title-items {
  cursor: pointer;
  margin-bottom: 5px;
  color: var(--admincat-color-grey-12);
}

.dropdown-inner-container {
  padding-left: 2.1875rem;
  height: 9.5rem;
  overflow-y: auto;
}

.dropdown-validInner-container {
  align-self: center;
  z-index: 1000;
  position: absolute;
  margin-top: 21rem;
  /* width: 3rem; */
}

.checkboc-list {
  width: 30rem;
  overflow-x: hidden;
  color: var(--admincat-color-grey-12);
}

.dropdown-inner-container-inputDateField {
  /* font-family: Helvetica, sans-serif; */
  padding-left: 2.1875rem;
  height: 5.5rem;
}

label {
  font-size: 14px;
  display: block;
  text-align: left;
  white-space: nowrap;
}

.inputDateField {
  width: 12rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.validityInputDateField {
  width: auto;
  display: flex;
  flex-direction: row;
}

input[type='checkbox'] {
  background: #dae0e7;
  width: 20px;
  height: 20px;
}

label {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.8125;
  letter-spacing: -0.017em;
  /* color: #aaafb4; */
  color: var(--admincat-color-grey-12);
  padding-left: 1rem;
  border-bottom: none;
  align-self: center;
  word-wrap: break-word;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  padding-left: 10px;
  font-size: 13px;
  align-self: center;
}

.fa-caret-up {
  padding-left: 10px;
  font-size: 13px;
  align-self: center;
}
/* 
.btn {
  background-color: #d5e4f7;
  color: red;
} */

div.selection-box-container {
  height: 3.6rem;
  background: var(--admincat-color-grey-13);
}

div.select-container {
  width: 12.5rem;
  position: relative;
}

div.dropdown-btn {
  height: 38px;
  display: flex;
  align-items: center;
  padding: 10px;
  background: #d5e4f7;
  color: #4576b3;
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px;
  cursor: pointer;
  justify-content: space-between;
  border: 0;
  transition: all ease-in-out 300ms;
}

div.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
}

div.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
div.dropdown-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
div.dropdown-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Some media queries for responsiveness */
@media screen and (max-height: 450px) {
  .filterAreaBox {
    padding-top: 15px;
  }
  .filterAreaBox a {
    font-size: 18px;
  }
}
