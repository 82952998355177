div.list_documents_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
  grid-gap: 1rem;
  margin-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 37rem;
  padding: 1rem;
}

.document_preview_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 11rem;
}

div.document_preview {
  position: relative;
  display: flex;
  border: 1.4px solid #ccc;
  height: 8.5rem;
  width: 6.2rem;
  border-radius: 0.5625rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  background: white;
}

.preview-content {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 0.5625rem;
}

.pdf-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 95%;
}

.pdf-page {
  /* Remove any margin/padding that might be added by react-pdf */
  margin: 0 !important;
  padding: 0 !important;
  /* Ensure the page is centered */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.pdf-page canvas {
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
}

.snippet {
  border-radius: 0.4625rem;
  height: 100%;
  width: 100%;
  align-items: center;
}

.documentTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 0.8625rem;
  line-height: 1.2;
  letter-spacing: -0.017em;
  color: #bcb3b3;
  padding-top: 0.5rem;
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

[aria-selected='true'] {
  box-shadow: 0 5px 8px 0 #739bcb, 0 5px 14px 0 #739bcb;
}

div.hideIcon {
  position: absolute;
  visibility: hidden;
  background-color: rgb(34, 33, 33);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  z-index: 1;
  align-self: flex-end;
  opacity: 0.8;
}

div.popIcon {
  visibility: visible;
  position: absolute;
  display: flex;
  width: 6.2rem;
  background-color: rgb(34, 33, 33);
  justify-content: space-evenly;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  z-index: 1;
  align-self: flex-end;
  opacity: 0.8;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

div.filtered_documents_container {
  /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); */
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;
  height: 90%;
  align-content: flex-start;
  margin: 0 auto 0 0;
  column-gap: 17px;
  row-gap: 10px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

div.filtered_documents_wrapper {
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  background-color: whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  padding-top: 5px;
  margin: 10px;
  column-gap: 17px;
}

div.filtered_document_preview {
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  height: 8rem;
  width: 5.9375rem;
  border-radius: 0.5625rem;
  text-align: center;
  align-items: flex-start;
  justify-content: center;
  margin: 10px;
  cursor: pointer;
}

div.pdf_snippets:hover {
  transform: translateY(-10px);
  justify-content: center;
}

div.filtered_preview_info {
  text-align: left;
  text-justify: auto;
  text-decoration: underline #739bcb;
  padding: 10px;
}

.filtered_preview_info p {
  font-style: italic;
  font-size: 0.9rem;
}

div.empty_state_container {
  display: flex;
  width: 100;
  height: 37rem;
  justify-content: center;
  align-items: center;
  /* background-color: #000; */
  /* background-color: red; */
}

.lottie_loader_document {
  display: flex;
  width: 100%;
  height: 37rem;
  /* background-color: #fff; */
  background-color: var(--admincat-color-grey-1);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading_text {
  font-style: normal;
  font-weight: 600;
  font-size: 11;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #9fa7b0;
  margin-bottom: 8px;
}
