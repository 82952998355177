
.flex {
  display: flex;
}

.hide {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex-one {
  flex: 1;
}

.flex-two {
  flex: 2;
}

.md-min-width {
  min-width: 270px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.row-reverse {
  flex-direction: row-reverse;
}

.space-between {
  justify-content: space-between;
}

.white-bg {
  background: #fff;
}

.margin-hori-auto {
  margin: 0 auto;
}

.margin-auto {
  margin: auto;
}

.space-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.list-style-none {
  list-style: none;
}

.text-center {
  text-align: center;
}

.circle {
  border-radius: 50%;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-italic {
  font-style: italic;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.outline-none {
  outline: none;
}

img.object-fit {
  object-fit: cover;
  width: 100%;
  height: auto;
  min-height: 100%;
}

.test {
  min-height: 200px;
  border: 1px solid red;
}

.bold {
  font-weight: 600 !important;
}
